import { ee } from "../../services/earth-engine";
import { mergeProperties, retrieveExtremes, getDate } from "../utils";
import {acquireFromDate as acquireFromDateLandSat} from "../../algorithms/satellite/landsat";
import { scoreCloudRatio } from "../imagery";
import * as Metadata from "../../common/metadata";

const addGridPosition = (element) => {
  const image = ee.Image(element);

  return image.set({ [Metadata.GRID_POSITION]: image.get("MGRS_TILE") });
};

const sliceByRevisit = (collection, startingDate, days) => {
  const start = ee.Date(startingDate).update(null, null, null, 0, 0, 0);
  const end = start.advance(days, "day");

  return collection.filterDate(start, end);
};


const getImageDate = function (image){
  var id = image.idString;
  if(image.idString === undefined){
    id = image.get('system:id').getInfo();
    console.error("Image without idString", image.getInfo(),"ID", id);
  } 
  var date = id.split("/");
  date = date[2].split("_"); //ee.String(date.get(2)).split("_");
  date  = date[0]; // date.get(0);
  let year = parseInt(date.substring(0,4)); 
  let monthIndex = parseInt(date.substring(4,6))-1;
  let day = parseInt(date.substring(6,8));
  var datetime = new Date(year,monthIndex,day);//ee.Date.parse('yyyyMMdd\'T\'HHmmss', date);

  return datetime;
} 

export class SentinelCloudCoverageCache{
  static list = []

  calculateCloudCoverage = function (geometry){
    var roi = geometry;
    var cloudProbThresh = 50; 
    try{
      var startDate = '2013-01-01';
      const endDate  = ee.Date(Date.now());

        // 02 - Def. function for cloud mask, pixel by pixel
        function maskClouds(image) {
          var cloudFree = ee.Image(image.get('cloudProbability')).lt(cloudProbThresh);
          return image.updateMask(cloudFree);
        }

      // 03 - Generate a imageCollection by joining two Sentinel missions: 
      //  COPERNICUS/S2_HARMONIZED (or SR) + COPERNICUS/S2_CLOUD_PROBABILITY
      console.log("try to acquire");
      var collection = ee.ImageCollection(
        ee.Join.saveFirst('cloudProbability').apply({
            primary: ee.ImageCollection('COPERNICUS/S2_HARMONIZED')
              .filterBounds(roi)
              .filterDate(startDate, endDate),
            secondary: ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
              .filterBounds(roi)
              .filterDate(startDate, endDate),
            condition: ee.Filter.equals({leftField: 'system:index', rightField: 'system:index'})
        })
      );
      console.log("acquired");
        // 04 - Get the information from Image Properties = system:index 
        //      (a) from the joined imageCollection or (b) the singe S2_CLOUD_PROBABILITY collection
        var cloudProbColl = ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
                      .filterBounds(roi)
                      .filterDate(startDate, endDate);

      // 05 - Calculate total area of the first image before cloud mask
      var firstImageArea = collection.first().clip(roi).select(0).multiply(ee.Image.pixelArea()).reduceRegion({
        reducer: ee.Reducer.sum(),
        scale: 10,
        bestEffort: true
      });

      // 06 - Apply the cloud mask function to the imageCollection
      var collCloudMask = collection.map(maskClouds);

      // 07 - Loop to calculate the percentage of cloud coverage
      //var temp02 = collCloudMask.toList(999);
      //var image = ee.Image(temp02.get(0));

      collCloudMask.forEach(image => {
        //image = image.clip(roi);
        var orCloudPercentage = image.get('CLOUDY_PIXEL_PERCENTAGE'); // get the original cloud information from the image properties 
        var cloud_prob = ee.Image(image.get('cloudProbability')).select('probability'); // get the probability of each pixel to be cloud 
        var is_cloud = cloud_prob.gt(cloudProbThresh).rename('CLOUDS'); // get only pixel with higher probability of being cloud
        var imageArea = is_cloud.multiply(ee.Image.pixelArea()); // get the coverage area of cloud pixels
        var sumPixClouds = imageArea.reduceRegion({
              reducer: ee.Reducer.sum(),
              //geometry: roi,
              scale: 10,
              maxPixels: 1e9,
          }); // get the sum of all cloud pixels area  
          var cloudArea = sumPixClouds.get('CLOUDS');
          var newCloudPercentage = ee.Number(cloudArea).divide(image.geometry().area());
          //return newCloudPercentage; 
          SentinelCloudCoverageCache[image.id()] = newCloudPercentage;
      });
    }catch(e){ 
      console.log("Error", e);
      return ee.Number(0.01);
    }
    console.log("SentinelCloudCoverageCache", SentinelCloudCoverageCache.list);
  }
}

export const maskS2ImageMask = (img, mission) =>{
  var cloudBitMask = ee.Number(2).pow(10).int();
  var cirrusBitMask = ee.Number(2).pow(11).int();
  var qa = img.select(mission.bands.qa);
  var ma = qa.bitwiseAnd(cloudBitMask).eq(0).and(
          qa.bitwiseAnd(cirrusBitMask).eq(0));

  ma = ma.focal_min({kernel: ee.Kernel.circle({radius: 1}), iterations: 1});
  img = img.mask(ma);
  return img;
};

export const maskS2Clouds = (img, geometry, bandName, scale, startingDate) => {

    var roi = geometry;
    var cloudProbThresh = 50; 
    let notProcessed = true; 
    if(startingDate !== undefined){ 
    /*
      let dtKey = ee.Date(startingDate).millis();
      const cloudCoverageAllDatesPerROI = JSON.parse(window.sessionStorage.getItem("cloudCoverageAllDatesPerROI"));
      let dateCloud = cloudCoverageAllDatesPerROI.filter(el => {return el.dt === dtKey});
      if(dateCloud.length >0 ) return dateCloud[0].cloud;
      */
      let dt202201 = new Date(2022,1,1);
      let useDateGt202201 = dt202201.getTime() < ee.Date(startingDate).millis();    
      if(useDateGt202201){          
          try{
            //var startDate = '2024-05-20';
            // var endDate = '2024-05-25';
            const startDate = ee.Date(startingDate).update(null, null, null, 0, 0, 0);
            const endDate  = startDate.advance(30, "day"); 

              // 02 - Def. function for cloud mask, pixel by pixel
              function maskClouds(image) {
                var cloudFree = ee.Image(image.get('cloudProbability')).lt(cloudProbThresh);
                return image.updateMask(cloudFree);
              }

            // 03 - Generate a imageCollection by joining two Sentinel missions: 
            //  COPERNICUS/S2_HARMONIZED (or SR) + COPERNICUS/S2_CLOUD_PROBABILITY
            var collection = ee.ImageCollection(
              ee.Join.saveFirst('cloudProbability').apply({
                  primary: ee.ImageCollection('COPERNICUS/S2_HARMONIZED')
                    .filterBounds(roi)
                    .filterDate(startDate, endDate),
                  secondary: ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
                    .filterBounds(roi)
                    .filterDate(startDate, endDate),
                  condition: ee.Filter.equals({leftField: 'system:index', rightField: 'system:index'})
              })
            );
              // 04 - Get the information from Image Properties = system:index 
              //      (a) from the joined imageCollection or (b) the singe S2_CLOUD_PROBABILITY collection
              var cloudProbColl = ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
                            .filterBounds(roi)
                            .filterDate(startDate, endDate);



          // 05 - Calculate total area of the first image before cloud mask
          var firstImageArea = collection.first().clip(roi).select(0).multiply(ee.Image.pixelArea()).reduceRegion({
            reducer: ee.Reducer.sum(),
            scale: 10,
            bestEffort: true
          });

          // 06 - Apply the cloud mask function to the imageCollection
          var collCloudMask = collection.map(maskClouds);

          // 07 - Loop to calculate the percentage of cloud coverage
          var temp02 = collCloudMask.toList(999);
            var image = ee.Image(temp02.get(0));
            //image = image.clip(roi);
            var orCloudPercentage = image.get('CLOUDY_PIXEL_PERCENTAGE'); // get the original cloud information from the image properties 
            var cloud_prob = ee.Image(image.get('cloudProbability')).select('probability'); // get the probability of each pixel to be cloud 
            var is_cloud = cloud_prob.gt(cloudProbThresh).rename('CLOUDS'); // get only pixel with higher probability of being cloud
            var imageArea = is_cloud.multiply(ee.Image.pixelArea()); // get the coverage area of cloud pixels
            var sumPixClouds = imageArea.reduceRegion({
                  reducer: ee.Reducer.sum(),
                  //geometry: roi,
                  scale: 10,
                  maxPixels: 1e9,
              }); // get the sum of all cloud pixels area  
              var cloudArea = sumPixClouds.get('CLOUDS');
              var newCloudPercentage = ee.Number(cloudArea).divide(image.geometry().area());
              notProcessed = false;
              return newCloudPercentage; 
          }catch(e){ 
            console.log("Error", e);
            return ee.Number(0.01);
          }
        }
      }

    if(notProcessed){
        // Print and plot the cloud filtered images
      //  print('Original cloud pixel percentage - ' + i + ':', orCloudPercentage);
       // print('New cloud pixel percentage - ' + i + ':',  newCloudPercentage);
      
        var image = img; 
        var cloudBitMask = 1 << 10;
        var cirrusBitMask = 1 << 11;
        var qa = image.select(bandName); 
        var cloud = qa.bitwiseAnd(cloudBitMask).eq(0);
        //var cirrus = qa.bitwiseAnd(cirrusBitMask).eq(0);  // Maybe it's time to abandon the cirrus cloud filter???
        //var expr = cloud.or(cirrus);
        var expr = cloud; // only cloud filters 
        //var cloudFiltered = image.updateMask(expr);
        var cloudPixelArea = expr.multiply(ee.Image.pixelArea());
        var res = cloudPixelArea.reduceRegion({
          reducer: ee.Reducer.sum(),
          geometry: geometry,
          scale: scale,
          maxPixels: 1e9,
        });
      
        var geometryArea = ee.Number(geometry.area());
        var cloudyArea = res.get(bandName);
        //console.log("RATIO-DEBUG", cloudyArea);
        var ratio = ee.Number(cloudyArea).divide(geometryArea);//ee.Number(100).multiply()
        ratio  = ee.Number(1).subtract(ratio); //to-do validate
        return ratio;
      }
      
};

export const acquireFromDate = (date, mission, geometry) => {

  if(mission.name.indexOf("LANDSAT")!==-1){
    return acquireFromDateLandSat(date, mission, geometry);
  }
  const slice = sliceByRevisit(
    ee.ImageCollection(mission.name).filterBounds(geometry),
    date,
    mission.cycle
  );
  //console.log("ACQUIRE FROM DATE - sentinel", mission);
  const mosaicked = slice.mosaic().clip(geometry);
  const bandName = "QA60";
  const scale = 60;
  const image = mosaicked.set(mergeProperties(slice));
  console.log("MaskS2Cloud - for mosaic on ", date);
  const ratio = maskS2Clouds(image,geometry,bandName,scale, date);

  try{
   // console.log("CLOUDS-PERCENTAG-DEBUG",date,"image", image.getInfo(),"ratio" , ratio.getInfo());
    return image.set("CLOUDS", ratio);
  }catch(e){
    console.log("Error clouds", e);
    return image.set("CLOUDS", ee.Number(0));
  }
  
  //const ratio = scoreCloudRatio(maskedImage, bandName, geometry, scale);
  //return image.set("CLOUDS", ratio); 
};

// Computes a list of valid dates in the region to be retrieved with acquireFromDate.
export const processCollection = (mission, geometry) => {
  const query = ee.ImageCollection(mission.name).filterBounds(geometry);

  // Retrieve the globally extreme dates (earliest and latest)
  const global = retrieveExtremes(query);

  // Compute the grid position of each image and sort in ascending order
  const enhanced = query.map(addGridPosition).sort(Metadata.GRID_POSITION);

  // Retrieve the northeasternmost grid position within the specified bounds
  const northeasternPosition = ee
    .Image(enhanced.toList(1).get(0))
    .get(Metadata.GRID_POSITION);

  // Keep images in the slice where the satellite passed first (northeast)
  const filtered = enhanced.filter(
    ee.Filter.eq(Metadata.GRID_POSITION, northeasternPosition)
  );

  // Retrieve the extremes in the northeastern position
  const northeastern = retrieveExtremes(filtered);

  // Compute the difference in days between the earliest image in the
  // northeastern position and the globally earliest image
  const difference = northeastern.earliest
    .difference(global.earliest, "day")
    .abs();
  const remainder = ee.Number(difference).mod(mission.cycle);

  // The amount of days we need to go back is given by the reverse of the
  // difference, in terms of the duration of an orbit
  const step = ee.Number(mission.cycle).subtract(remainder);

  // Compute the date of the earliest possible image in the northeastern
  // position (whether or not it exists) by going back in time
  const earliestDate = global.earliest.advance(step.multiply(-1), "day"); // 1.21 gigawatts!

  // Compute the amount of complete orbital cycles between the earliest and
  // latest possible dates of the images in the northeastern position (whether
  // or not they exist)
  const completeCycles = ee
    .Number(earliestDate.difference(global.latest, "day").abs())
    .divide(mission.cycle)
    .ceil();

  // Generate slices of 5 (0, 5, 10, 15, ...), one for each complete cycle
  const additions = ee.List.sequence(0, null, mission.cycle, completeCycles);

  // Transform each slice into an empty image. If the slice contains at least
  // one image, we add metadata related to the correspondent orbital cycle,
  // to allow for filtering later
  const carriers = additions.map((increment) => {
    const startingDate = earliestDate.advance(increment, "day");
    const collection = sliceByRevisit(query, startingDate, mission.cycle);

    const empty = ee.Algorithms.IsEqual(collection.size(), 0);
    const properties = ee.Algorithms.If(empty, {}, mergeProperties(collection));

    return ee.Image(0).set(properties);
  });

  // Keep only the images whose combined geometries contain the AOI
  const valid = ee.ImageCollection.fromImages(carriers).filter(
    ee.Filter.contains(Metadata.FOOTPRINT, geometry)
  );

  // For now only the date of the slice is important.
  return ee.List(valid.toList(valid.size()).map(getDate));
};

export const generateCloudMap = (dates, mission, geometry) => {
  const cloudList = ee.List(dates).map((date) => {
    const image = ee.Image(acquireFromDate(date, mission, geometry));
    return image.get("CLOUDS");
  });

  return cloudList;
};

const queryAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const getAvailable = (mission) => (geometry) => {
  const query = processCollection(mission, geometry);
  // Format Data as ISO standart formating and and UTC
  const datesQuery = query.map((date) => ee.Date(date).format(null, 'UTC'));
  const cloudQuery = generateCloudMap(datesQuery, mission, geometry);

  return ee.Dictionary.fromLists(datesQuery, cloudQuery);
};

const acquire = (mission) => (date, geometry) => {
  if(window.location.href.indexOf("bathymetry") !== -1) return false;
  console.log("PARAMS", date, mission, geometry);
  return acquireFromDate(date, mission, geometry);
};

const format = (properties) => {
  return properties["system:time_start"] + " -- " + properties["cloud"];
};

export default {
  queryAvailable,
  getAvailable,
  acquire,
  format,
};
