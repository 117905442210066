import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import HomePageLayout from "../../components/homepage/HomePageLayout";
import bathymetryThumb from "../../../resources/bathymetryThumb.jpg";
import shorelinesThumb from "../../../resources/shorelinesThumb.jpg";
import csqueezeThumb from "../../../resources/cqueezeThumb.jpg";
import intermareThumb from "../../../resources/intermares_logo.png";
import AlgorithmCard from "../../components/acquisition/AlgorithmCard";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { ee } from "../../../services/earth-engine";
import { acquireFromDate } from "../../../algorithms/satellite/sentinel";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
  },
  header: {
    backgroundColor: theme.palette.primary[400],
    padding: theme.spacing(6),
  },
  title: {
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
  },
  content: {
    width: "80vw",
    maxWidth: "1000px",
    marginTop: theme.spacing(-9),
  },
  instructions: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  grid: {
    margin: theme.spacing(6, 0),
  },
  item: {
    maxWidth: "25%",
    maxHeight: "500px",
  },
}));

const SelectionPage = () => {
  const dispatch = useDispatch();
  const handleChoice = (name) => {
    ReactGA.event({
      category: "Algorithm",
      action: "ChooseAlgorithm",
      value: name.split(" ", 1)[0],
    });

    name =  name.toLowerCase();
    if(name.indexOf("intertidal")!== -1 || name.indexOf("intermaré") !== -1){
      dispatch(push("intertidal/1"))
      return;
    }


    name.indexOf("shoreline") !== -1 ||
    name.indexOf("linha de costa") !== -1
      ? dispatch(push("acquisition/1"))
      : name.indexOf("batimetria")!== -1 || name.indexOf("bathymetry") !== -1
      ? dispatch(push("bathymetry/1"))
      : dispatch(push("csqueeze/1"));
  };

  //clear intertidal parameters before module init
 //window.sessionStorage.removeItem("AOIIntertidal");
 //window.sessionStorage.removeItem("tideData");
 //window.sessionStorage.removeItem("intertidalPeriodData");    
 

  const [t] = useTranslation();
  const classes = useStyles();
  const availableAlgorithms = [
    {
      name: t("forms.algorithm.shoreline"),
      image: shorelinesThumb,
      description: t("forms.algorithm.shorelineDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.bathymetry") + " (beta)",
      image: bathymetryThumb,
      description: t("forms.algorithm.bathymetryDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.csqueeze") + " (beta)",
      image: csqueezeThumb,
      description: t("forms.algorithm.csqueezeDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.intermare") ,
      image: intermareThumb,
      description: t("forms.algorithm.intermareDesc"),
      enabled: false,
    },
  ];
  scriptToTest();

  return (
    <HomePageLayout>
      <div className={classes.wrapper}>
        <Grid item xs={12} className={classes.header} >
          <div>
            <Typography className={classes.title} variant="h4" align="center">
              {t("forms.algorithm.title")}
            </Typography>
          </div>
        </Grid>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          id="algorithmchooser"
          className={classes.grid}
        >
          {
            // map the satellites to satellite cards
            availableAlgorithms.map((algorithm, i) => (
              <Grid
                item
                key={i}
                xs={4}
                id={algorithm.name}
                className={classes.item}
              >
                <AlgorithmCard
                  name={algorithm.name}
                  image={algorithm.image}
                  description={algorithm.description}
                  onChoose={() => handleChoice(algorithm.name)}
                  enabled={algorithm.enabled}
                />
              </Grid>
            ))
          }
        </Grid>
      </div>
    </HomePageLayout>
  );

  function scriptToTest() {
    return false;
    //https://code.earthengine.google.com/eddcdfce3b08a9330e172f991390dcd4

    var table = ee.FeatureCollection(
        "users/rafaelqg/batimetria_babitonga_2018_50K"
      ),
      geometry =
        /* color: #0b4a8b */
        /* shown: false */
        ee.Geometry.Polygon([
          [
            [-48.624656947326656, -26.181710245683796],
            [-48.59530285186767, -26.22298800628459],
            [-48.57281521148681, -26.213901994461565],
            [-48.605087550354, -26.176472469036128],
          ],
        ]),
      visRGB = { min: 0, max: 3000, bands: ["B4", "B3", "B2"] },
      visDepth = {
        bands: ["depth"],
        min: 0,
        max: 20,
        palette: ["00FFFF", "0000FF"],
      };

    //console.log("Table", table, table.getInfo());
    //intervalo de datas
    //arquivo de treino, kml (região),data (foto), profundidade (linha 90)
    //print ("table", table, typeof(table))
    // QA60 band cloud bits
    var cloudBitMask = ee.Number(2).pow(10).int();
    var cirrusBitMask = ee.Number(2).pow(11).int();

    // Mask out land and clouds
    function mask(img) {
      var qa = img.select("QA60");
      var ma = qa
        .bitwiseAnd(cloudBitMask)
        .eq(0)
        .and(qa.bitwiseAnd(cirrusBitMask).eq(0));

      ma = ma.and(img.select(["B9"]).lt(300));
      ma = ma.and(img.select(["B9"]).gt(50));
      ma = ma.and(img.select(["B3"]).gt(100));
      //adjust for mask bad data
      img = img.updateMask(img.select([4]).lt(1000));
      img = img.updateMask(img.select([7]).lt(300));
      ma = ma.focal_min({
        kernel: ee.Kernel.circle({ radius: 1 }),
        iterations: 1,
      });
      img = img.mask(ma);

      var ndwi_revise = img
        .select([2])
        .subtract(img.select([7]))
        .divide(img.select([2]).add(img.select([7])));
      img = img.updateMask(ndwi_revise.gt(0));

      return img;
    }

    // Create a FeatureCollection containing each points' band information and depth
    function createPoints(el) {
      return ee.Feature(
        ee.Geometry.Point([el.get("X"), el.get("Y")], "EPSG:32722"),
        { depth: el.get("Z") }
      );
    }

    // Calculate the NDWI value (Green and Blue bands) and the Reason (Green and Blue bands)
    function calculateReason(el) {
      return ee
        .Feature(el.geometry())
        .set("depth", el.get("depth"))
        .set(
          "ndwi",
          ee.Feature(
            ee
              .Number(el.get("B3"))
              .subtract(el.get("B2"))
              .divide(ee.Number(el.get("B3")).add(el.get("B2")))
          )
        )
        .set(
          "ratio",
          ee.Feature(
            ee
              .Number(el.get("B3"))
              .multiply(1)
              .log()
              .divide(ee.Number(el.get("B2")).multiply(1).log())
          )
        )
        .set("constant", 1);
    }

    // Predict depth based on the linear model results
    function predictDepth(el) {
      return ee
        .Feature(el.geometry())
        .set("depth", el.get("depth"))
        .set(
          "predicted_depth",
          ee.Number(el.get("ndwi")).multiply(slope).add(yInt)
        )
        .set(
          "diff",
          ee
            .Number(el.get("depth"))
            .subtract(ee.Number(el.get("ndwi")).multiply(slope).add(yInt))
            .abs()
            .pow(2)
        );
    }

    // Create raster with the predicted depth
    function createPredictionRaster(el) {
      var ndwi = el
        .select("B3")
        .subtract(el.select("B2"))
        .divide(el.select("B3").add(el.select("B2")))
        .rename("NDWI");
      el = el.addBands(ndwi);
      var depth = el
        .select("NDWI")
        .multiply(ee.Number(slope))
        .add(ee.Number(yInt))
        .rename("depth");
      return el.addBands(depth);
    }

    // Map.setCenter(-48.5932,-26.1957, 14)

    var points = table.map(createPoints);
    //console.log("points", points,points.getInfo());
    var sentinel = ee
      .ImageCollection("COPERNICUS/S2")
      .filterBounds(geometry)
      .map(function (image) {
        return image.clip(geometry);
      });
    sentinel = sentinel.filter(
      ee.Filter.date(ee.Date.fromYMD(2018, 5, 20), ee.Date.fromYMD(2018, 5, 21))
    );
    var temp = sentinel.toList(999);
    var image = ee.Image(temp.get(1));
    //var collection_reduce = collection.median()

    //print ("Array", image.toArray(),image.toArray(2))
    //Map.addLayer(image, visRGB, 'RGB');
    //var imageWithMask = mask(image);
    //Map.addLayer(imageWithMask, visRGB, 'With Mask');

    // Associate each point with the band values retrived from the Sentinel image
    var pointFilter = points.filterBounds(geometry);
    var pointData = image.reduceRegions({
      collection: pointFilter,
      crs: ee.Projection("EPSG:32722"),
      scale: 10,
      reducer: ee.Reducer.median(),
    });

    // Filter points with depth ≤ 17 meters
    pointData = pointData.filter(ee.Filter.lte("depth", 17));
    var trainingData = pointData.map(calculateReason, true);

    //TCC Sabrina: otimizar o calculo dos coeficientes
    // Apply a Linear Regression model to the data
    var linearRegression = ee.Dictionary(
      trainingData.reduceColumns({
        reducer: ee.Reducer.linearRegression({
          numX: 2,
          numY: 1,
        }),
        selectors: ["constant", "ndwi", "depth"],
      })
    );

    var coefList = ee.Array(linearRegression.get("coefficients")).toList();

    var yInt = ee.List(coefList.get(0)).get(0); // y-intercept
    var slope = ee.List(coefList.get(1)).get(0); // slope

    console.log("Interception = ", yInt);
    console.log("Slope = ", slope);

    // Predict depth of the training data to measure RMSE
    var predictedData = trainingData.map(predictDepth, true);

    // Export.table.toDrive(predictedData, "predictedDepth200518")

    // Calculate RMSE value
    var rmse = ee
      .Number(
        predictedData.reduceColumns(ee.Reducer.sum(), ["diff"]).get("sum")
      )
      .divide(predictedData.size())
      .sqrt();
    console.log("RMSE =", rmse);

    // Create masked Sentinel image
    var predicted_sentinel = mask(image);

    // Create prediction raster
    predicted_sentinel = createPredictionRaster(predicted_sentinel);

    // Map.addLayer(predicted_sentinel, visDepth, 'Predicted Depth');

    var minMax = ee.Feature(
      null,
      predicted_sentinel.reduceRegion({
        reducer: ee.Reducer.minMax(),
        geometry: geometry,
      })
    );

    visDepth = {
      region: geometry,
      bands: ["depth"],
      min: 0,
      max: 20,
      palette: ["00FFFF", "0000FF"],
      dimensions: 512,
      format: "jpg",
    };
    let thumbURL = predicted_sentinel.getThumbURL(visDepth);

    //let thumbURL = predicted_sentinel.getThumbURL({region: geometry,bands:["B4","B3","B2"], dimensions: 512,max:2000, min:3000, format: 'jpg'});

    console.log("thumbURL", thumbURL);
    console.log(
      "Minimum predicted depth: ",
      minMax.getNumber("depth_min"),
      minMax.getNumber("depth_min").getInfo(),
      " meters"
    );
    console.log(
      "Maximum predicted depth: ",
      minMax.getNumber("depth_max"),
      minMax.getNumber("depth_max").getInfo(),
      " meters"
    );

    /*
        var cloudBitMask = ee.Number(2).pow(10).int();
        var cirrusBitMask = ee.Number(2).pow(11).int();
    
        // Mask out land and clouds
        function mask(img){
          var qa = img.select('QA60');
          var ma = qa.bitwiseAnd(cloudBitMask).eq(0).and(
                    qa.bitwiseAnd(cirrusBitMask).eq(0));

          ma = ma.and(img.select(['B9']).lt(300)); 
          ma = ma.and(img.select(['B9']).gt(50));
          ma = ma.and(img.select(['B3']).gt(100));
          //adjust for mask bad data
          img = img.updateMask(img.select([4]).lt(1000));
          img = img.updateMask(img.select([7]).lt(300))
          ma = ma.focal_min({kernel: ee.Kernel.circle({radius: 1}), iterations: 1});
          img = img.mask(ma);

          var ndwi_revise = (img.select([2]).subtract(img.select([7]))).divide(img.select([2]).add(img.select([7])));
          img = img.updateMask(ndwi_revise.gt(0));
        
          return img;
        };
        var geometry = 
        ee.Geometry.Polygon(
            [[[-48.59530285186767,-26.22298800628459],
            [-48.57281521148682,-26.213901994461562],
            [-48.605087550354,-26.176472469036128],
            [-48.624656947326656,-26.181710245683796]]]); //, null, false
        var image = ee.ImageCollection('LANDSAT/LC09/C02/T1_L2').filterBounds(geometry).filter(ee.Filter.date('2021-10-01', '2022-03-31')).first();
        console.log("IMAGE-GEE-TEST-1", image.getInfo());
        
        //begin test median
        let collection = ee.ImageCollection('COPERNICUS/S2').filterDate('2022-01-01', '2022-06-30');
        collection = collection.map(mask);
        // Compute the median in each band, each pixel.
        // Band names are B1_median, B2_median, etc.
        var medianImage = collection.reduce(ee.Reducer.median());
        //print(medianImage.getInfo())
        // The output is an Image.  Add it to the map.
      // var vis_param = {bands: ['B4_median', 'B3_median', 'B2_median'], gamma: 1.6};
        let medianThumbURL = medianImage.getThumbURL({region: geometry,bands:['B4_median', 'B3_median', 'B2_median'], dimensions: 512,max:1700, min:300, format: 'jpg'});
        console.log("thumbURL Median", medianThumbURL);
        //end test median
        
        
        
        //let dt = new Date(2021,10,1);
        //
        //2022-09-16T13:29:37
        let ms = {
          "name": "COPERNICUS/S2",
          "shortname": "S2",
          "cycle": 5,
          "startYear": 2013,
          "endYear": null,
          "opticalResolution": 10,
          "bands": {
              "blue": "B2",
              "green": "B3",
              "red": "B4",
              "nir": "B8",
              "swir": "B11"
          },
          "vizParams": {
              "max": 3000,
              "min": 0
          },
          "properties": {
              "cloudCoverProperty": "CLOUDY_PIXEL_PERCENTAGE"
          },
          "fallbackMission": null
      }
      
        const image2 = ee.Image(acquireFromDate('2022-09-16T13:29:37', ms, geometry));
        console.log("IMAGE-GEE-TEST-2-MOSAIC", image2.getInfo());
        let thumbURL = image2.getThumbURL({region: geometry,bands:["B4","B3","B2"], dimensions: 512,max:4000, min:1000, format: 'jpg'});
        console.log("thumbURL", thumbURL);

        function applyScaleFactors(img) {
          var opticalBands = img.select('SR_B.').multiply(0.0000275).add(-0.2);
          var thermalBands = img.select('ST_B.*').multiply(0.00341802).add(149.0);
          return img.addBands(opticalBands, null, true).addBands(thermalBands, null, true);
        }
        image = applyScaleFactors(image);  
        let bands =  ["SR_B4", "SR_B3", "SR_B2"]; //mission.bands.red, mission.bands.green, mission.bands.blue];
      let visualization = {
          bands: bands,
          min: 0.0,
          max: 0.3, 
        };
        image = image.visualize(visualization);
        console.log("IMAGE-GEE-TEST-2", visualization, image, image.getInfo());
        */
  }
};

export default SelectionPage;
