import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


import ee from "../../../services/earth-engine";
import {
  Box,
  Button,
} from "@material-ui/core";
import GoogleMap from "../map/GoogleMap";
import StepperButtons from "./StepperButtons";
import * as Map from "../../../common/map";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import HelpButton from "../core/HelpButton";
import { get as getSatellite } from "../../../common/satellites";
import {maskS2Clouds as maskS2CloudsSentinel} from "../../../algorithms/satellite/sentinel";

// useStyles is a hook for styling this component with Material UI's styling solution

// This is the page of the second step of the aquisition wizard
// it is supposed to get the Area of Interest (AOI) from the map
// and to save it in the storage.
// It also shows a button to start the third step.
const AOIChooser = ({ navigate }) => {
  const dispatch = useDispatch();
  // get the current language
  const [t] = useTranslation();
  const [overlay, setOverlay] = useState(null);
  const [coordinates, setCoordinates] = useState(null);

  let url = window.location.href;
  const isBathymetry = url.indexOf("bathymetry") != -1;
  const [isDataTranningSelected, setIsDataTranningSelected] = useState(false);
  const [tranningData, setTranningData] = useState([]);
  const [tranningDate, setTranningDate] = useState(null);
  const [message, setMessage] = useState("");
  let saveZoomChange= function(){
    let zoom=Map.getZoomLevel();
    if(typeof(zoom)!=="undefined" && zoom !=="undefined"){
      window.sessionStorage.setItem("zoom_level",zoom);
    }
  }
  window.sessionStorage.removeItem("zoom_level");//clear previous saved zoom (aquisition -first use case)
  setInterval(saveZoomChange,5000);
  useEffect(() => {
    Map.setDrawingControlsVisible(true); 
    return () => {
      Map.onZoomChange(() => {
        saveZoomChange();
      });
    };
  }, []);

  //test default poligonon
  //https://geojson.io/

  const bathymetryProcess = function (){

  }

  // handle drawing the AOI on the map
  const handleDrawing = (overlay, coordinates) => {
    setOverlay(overlay);
    setCoordinates(coordinates);
    // disable the drawing controls, when the AOI is drawn
    Map.setDrawingControlsVisible(false);
  };

  // handle the undoing of the AOI
  const handleUndo = () => {
    overlay.setMap(null);
    setOverlay(null);
    setCoordinates(null);
    // enable the drawing controls
    Map.setDrawingControlsVisible(true);
  };

  const saveCloudCoverageValues = function (startDate, endDate, roi){
      ///////////////////////////////////////////////////////////////////////////////////////
      // New GEE Sentinel-2 cloud filter for CASSIE, using S2_CLOUD_PROBABILITY collection //
      //                                                                        06/08/2024 //
      ///////////////////////////////////////////////////////////////////////////////////////

      // 01 - Set Global variables
      var cloudProbThresh = 50; // The lower the probability, the greater the cloud clipping in the image!

      // 02 - Def. function for cloud mask, pixel by pixel
      function maskClouds(image) {
        var cloudFree = ee.Image(image.get('cloudProbability')).lt(cloudProbThresh);
        return image.updateMask(cloudFree);
      }

      // 03 - Generate a imageCollection by joining two Sentinel missions: 
      //      COPERNICUS/S2_HARMONIZED (or SR) + COPERNICUS/S2_CLOUD_PROBABILITY
      var collection = ee.ImageCollection(
        ee.Join.saveFirst('cloudProbability').apply({
            primary: ee.ImageCollection('COPERNICUS/S2_HARMONIZED')
              .filterBounds(roi)
              .filterDate(startDate, endDate),
            secondary: ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
              .filterBounds(roi)
              .filterDate(startDate, endDate),
            condition: ee.Filter.equals({leftField: 'system:index', rightField: 'system:index'})
        })
    );

      // 04 - Get the information from Image Properties = system:index 
      //      (a) from the joined imageCollection or (b) the singe S2_CLOUD_PROBABILITY collection
      var cloudProbColl = ee.ImageCollection('COPERNICUS/S2_CLOUD_PROBABILITY')
                          .filterBounds(roi)
                          .filterDate(startDate, endDate);

    var temp01 = cloudProbColl.toList(999); // Use this for single cloud probability collection
    for (var i = 0; i < temp01.size().getInfo(); i++) {
        var getProp = ee.Image(temp01.get(i)).get('system:index');
      // print('getProp_system_index ' + i + ':', getProp);
    }

    // 05 - Calculate total area of the first image before cloud mask
    var firstImageArea = collection.first().clip(roi).select(0).multiply(ee.Image.pixelArea()).reduceRegion({
      reducer: ee.Reducer.sum(),
      scale: 10,
      bestEffort: true
    });

    // 06 - Apply the cloud mask function to the imageCollection
    var collCloudMask = collection.map(maskClouds);

    // 07 - Loop to calculate the percentage of cloud coverage
    var temp02 = collCloudMask.toList(999);
    var mapOutput = [];
    for (var i = 0; i < temp02.size().getInfo(); i++) {
      var image = ee.Image(temp02.get(i));
      var orCloudPercentage = image.get('CLOUDY_PIXEL_PERCENTAGE'); // get the original cloud information from the image properties 
      var cloud_prob = ee.Image(image.get('cloudProbability')).select('probability'); // get the probability of each pixel to be cloud 
      var is_cloud = cloud_prob.gt(cloudProbThresh).rename('CLOUDS'); // get only pixel with higher probability of being cloud
      var imageArea = is_cloud.multiply(ee.Image.pixelArea()); // get the coverage area of cloud pixels
      var sumPixClouds = imageArea.reduceRegion({
            reducer: ee.Reducer.sum(),
            //geometry: roi,
            scale: 10,
            maxPixels: 1e9,
        }); // get the sum of all cloud pixels area
        var cloudArea = sumPixClouds.get('CLOUDS');
        var newCloudPercentage = ee.Number(100).multiply(ee.Number(cloudArea).divide(image.geometry().area())); 
        // Adding a cloud coverage percentage band to the images in the image collection 
        var imageCloudPercBand = image.set("CLOUDS_PERC",  newCloudPercentage); // Use imageCloudPercBand to mask images with gt pre-defined % 
        var imageDate = image.get('system:time_end');
        var imageDateObject = ee.Date(imageDate );
        mapOutput.push(ee.Feature(null, {"dt": imageDate, "cloud": newCloudPercentage})) ;
      }

    window.sessionStorage.setItem("cloudCoverageAllDatesPerROI", JSON.stringify(ee.FeatureCollection(mapOutput).getInfo().features.map( el => el.properties) ));
  }


  const getSelectedSatellite = function (){
    let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
    let satellite = getSatellite(selectedSatelliteIndex);
    return satellite;
  }
  // handle the saving of the AOI
  const handleChoose = () => {
    let satellite = getSelectedSatellite();
    let startYear = 2024; //satellite.missions[0].startYear;
    let endYear = new Date().getFullYear();
    //`${startYear}-01-01`
    //`${endYear}-12-31`
    //saveCloudCoverageValues ('2024-05-01', '2024-05-25', ee.Geometry.Polygon([coordinates]));
    try{
        dispatch(
          Acquisition.setAOI(
            overlay,
            coordinates,
            ee.Geometry.Polygon([coordinates])
          )
        );
    }catch(e){
      window.alert("Your account still is not allowed to use GEE API. You should complete your register using this link: https://earthengine.google.com/signup/");
      window.location = "https://earthengine.google.com/signup/";

      //setTimeout(()=>{},2000); 
    }

  };

  // check if the AOI is already saved
  const drawn = Boolean(coordinates);
  console.log("Is Bathemetry", isBathymetry);
  // defines the steps for the tour
  const steps = [
    {
      selector: "#areachooser",
      content: t("tour.acquisition.2.info"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showROITour", true);
  

  const readFileTrainingData = function () {
    let fileField=document.getElementById("file_import_training_data");
    if(fileField.files.length===0){
      window.alert("Please select a file first.");
      return false;
    }
    const file = fileField.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", function () {
       // console.log("File data:", reader.result);
        const fileContent=reader.result;
        let lines = fileContent.split("\n");
        lines = lines.splice(1,lines.length);
        let matrix = [];
        lines.forEach(line =>{
          let columns = line.split(",");
          if(columns[0] !== "" && columns[0] !==  null && !Number.isNaN(Number.parseFloat(columns[0]))){
            let x = Number.parseFloat(columns[0]);
            let y = Number.parseFloat(columns[1]);
            let z = Number.parseFloat(columns[2]);
            matrix.push([x, y, z]);
          }
        });
        //closeTrainingFilePanel();
        setIsDataTranningSelected(true);
        setTranningData(matrix);
        //console.log("matrix-data-training", matrix, isDataTranningSelected );

        //extract data from file name
        let fileName = fileField.value;
       // console.log("fileName", fileName);
        let posUnterscore = fileName.lastIndexOf("_");
        let posDot = fileName.lastIndexOf(".");
        if(posDot - posUnterscore >=6){
          let day  = fileName.substring(posUnterscore+1,posUnterscore+3);
          let month = fileName.substring(posUnterscore+3,posUnterscore+5);
          let year = fileName.substring(posUnterscore+5,posUnterscore+9);
         // console.log("Date", day, month, year);
          document.getElementById("date_traning_file").value = year + "-" + month + "-" + day;
        }
        //console.log();
    }, false);
     if (file) {
        reader.readAsText(file);
    }
  }   


  const readFileData = function () {
        let fileField=document.getElementById("file_import_kml");
        if(fileField.files.length===0){
          window.alert("Please select a file first.");
          return false;
        }
        const file = fileField.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            console.log("File data:", reader.result);
            const fileContent=reader.result;
            const kmlTokenBegin="<coordinates>";
            const kmlTokenEnd="</coordinates>";
            let isKml=fileContent.indexOf(kmlTokenBegin)!==-1;
            if (isKml){
              let coordinatesString=fileContent.split(kmlTokenBegin)[1].split(kmlTokenEnd)[0];
              let coordinatesArrayStrings=coordinatesString.split(" ").map(el=>el.trim());
              let coordinatesArray=[];
              coordinatesArrayStrings.forEach(el=>{
                if(el.trim() !== "" ){
                  let coordinatesValues=el.split(",");
                  coordinatesValues=coordinatesValues.map(el=>parseFloat(el));
                  if(!isNaN(coordinatesValues[0])){
                    coordinatesArray.push([coordinatesValues[0],coordinatesValues[1]]);
                  }
                }
              });
              console.log("coordinatesValues", coordinatesArray);
              //select area
              if(coordinatesArray.length>0){
                let overlayFixed=Map.drawOutline(coordinatesArray);
                setOverlay(overlayFixed);
                setCoordinates( coordinatesArray );
                Map.centralize( coordinatesArray[0][1], coordinatesArray[0][0] );
                Map.setDrawingControlsVisible(false);
                closeFilePanel();
                setMessage("KML file processed.");
                //plotTranningData();
              }else{
                window.alert("No valid coordinates were found in the KML file.");
              }
            }else{
                window.alert("It was not possible to interpret the selected file.");
            }            
        }, false);
         if (file) {
            reader.readAsText(file);
        }
      }   
      
      const handleDisplayImportKML= function (){
        let dialog = document.getElementById("dialog_kml");
        dialog.showModal();
      }

      const handleDisplayImportTrainingData = function (){
        let dialog = document.getElementById("dialog_training_csv");
        dialog.showModal();
        let coordinates_system = document.getElementById("coordinates_system");
        if(coordinates_system.value === ""){
          coordinates_system.value = "EPSG:4326";
          window.sessionStorage.setItem("coordinatesSystem", "EPSG:4326");
        }
        
      }


    
      const closeFilePanel = function (){
        let dialog = document.getElementById("dialog_kml");
        dialog.close();
      }

      const closeTrainingFilePanel = function (){
        let dialog = document.getElementById("dialog_training_csv");
        dialog.close();
      }

      const confirmTrainingFilePanel = function (){
        let dateTranning = document.getElementById("date_traning_file").value;
        let coordinatesSystem = document.getElementById("coordinates_system").value;
        if (coordinatesSystem === ""){
          window.alert("Please inform the coordinates system.");
          return;
        }
        if(!isDataTranningSelected){
          window.alert("Please select the tranning data file.");
        }else if(dateTranning === ""){
          window.alert("Please inform the date before continue.");
        }else{
          let matrix = tranningData;
          if(coordinatesSystem.indexOf("4326") === -1 ){
            // Creating and applying the funciton to tranform the EPSG
            let points = tranningData.map( point => {
              return ee.Feature(ee.Geometry.Point([point[0],point[1]], coordinatesSystem));
            });
            var utm2latlon = points.map(function(feature) {
              return feature.transform('EPSG:4326');
            });
            let fc = ee.FeatureCollection(utm2latlon);
            let coordinatesConverted = fc.getInfo();
            matrix = [];
            coordinatesConverted.features.forEach(el => {
              matrix.push(el.geometry.coordinates);
            });
            console.log("matrix", matrix);
          }

          
          Acquisition.setTranningData(tranningData);
          Acquisition.setTranningDate(dateTranning);
          setMessage("Trainning file processed.");
          closeTrainingFilePanel();
          //plotting points on map
          plotTranningData(matrix);      
        }
      }

      const plotTranningData = function (matrix){
        let table = [];
        matrix.forEach(row => { //substituir por função
            table.push([row[0], row[1]]);
          });
          console.log("table", table);
          console.log("drawing trainning data... v2");
          let samples = table;
          let coordinatesArray = samples;
          console.log("coordinatesArray", coordinatesArray);
          Map.drawCircle(coordinatesArray); //https://developers.google.com/maps/documentation/javascript/examples/circle-simple
         // Map.drawOutlinePoints(coordinatesArray);
          Map.centralize(coordinatesArray[0][1], coordinatesArray[0][0] );
          console.log("drawing trainning data ends..."); 
      };

      const displayTrainingButton = function (){       
          if(isBathymetry){
            return "inline-block";
          }
          return "none";
      }

      const showHelpCoordinatesSystem = function(){
        document.getElementById("help_coordinate_system").showModal();
      }

      const showHelpDateTranning = function(){
        document.getElementById("help_date_tranning").showModal();
      }

      const showHelpTranningFile = function(){
        document.getElementById("help_tranning_file").showModal();
      }

      
    
      console.log("rendering",isBathymetry,"isDataTraningSelected", isDataTranningSelected,  !isDataTranningSelected || !drawn);
//https://epsg.io/
      return (
    <Box> 
      <div align="center">
        {t("forms.acquisition.2.bathymetryDescription")}<br/>
        {isBathymetry ?
        <div>
          {t("forms.acquisition.2.example")}:&nbsp;<br />
          Babitonga: <a href={"/babitonga.kml".toString()}>Area of interesst (.kml)</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href={"/batimetria_1K_20052018.csv".toString()}>Trainning data file (.csv) - EPSG:32722</a>
          <br />
          Babitonga: <a href={"/babitonga.kml".toString()}>Area of interesst (.kml)</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href={"/batimetria_1K_ESPG4326positivo_20052018.csv".toString()}>Trainning data file (.csv) - EPSG:4326</a>
        </div>
        : <span></span>}
      </div>
      <div align="center" style={{color:"darkgreen", size: "14px", padding:"10px" }}>{message}</div>
      <Button onClick={handleDisplayImportKML} style={{cursor:"pointer", color:"#26a69a", left:660, top:100,backgroundColor:"#FFF", zIndex:99}}>{t("forms.acquisition.2.importTitle")}</Button>
      <dialog id="dialog_kml" >
        <fieldset style={{borderColor:"#BBB", display:"block"}} id="import_kml_component">
          <legend> {t("forms.acquisition.2.importLegend")}</legend>
            <input id="file_import_kml" accept=".kml" type="file" onChange={readFileData}  />
          </fieldset>
          <br />
          <Button
              color="secondary"
              variant="contained"
              onClick = {closeFilePanel}
            >
              {t("forms.map.cancel")}
            </Button>
      </dialog>
      <Button onClick={handleDisplayImportTrainingData} style={{cursor:"pointer", display:displayTrainingButton(), color:"#26a69a", left:675, top:100,backgroundColor:"#FFF", zIndex:99}}>{t("forms.acquisition.2.dataTrainning")}</Button>
      <dialog id="dialog_training_csv" >
        <fieldset style={{borderColor:"#BBB", display:"block"}} id="import_csv_component">
          <legend>{t("forms.acquisition.2.dataTrainningLegend")}</legend>
            <svg onClick={showHelpTranningFile} style={{"font-size":"24"}} class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
            &nbsp;
            <input id="file_import_training_data" accept=".csv" type="file" onChange={readFileTrainingData}  />
            
            <p>{t("forms.acquisition.2.dataTrainningFileLabel")}</p>

            <svg onClick={showHelpDateTranning} style={{"font-size":"24"}} class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
            &nbsp;
            <input type="date" id="date_traning_file" />
      
            <br /><br />
            Coordinates system*:
            <br />
            <span onClick={showHelpCoordinatesSystem} >
              <svg style={{"font-size":"24"}} class="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
            </span>
            &nbsp;
            <select style={{display:'none'}}><option>EPSG:32722</option></select>
            <input list="coordinates_list" id="coordinates_system" onInput={()=>{
                window.sessionStorage.setItem("coordinatesSystem", document.getElementById("coordinates_system").value);
              }} 
              /> (example type "EPSG:4326".)
              <div>Find coordinates system values here: <a href="https://epsg.io" target="blank">https://epsg.io</a></div>
            <datalist id="coordinates_list">
              <option value="EPSG:32722" ></option>
              <option value="EPSG:4326" ></option>
            </datalist>
            <br/>
          </fieldset>
          <br />
          <Button
              color="secondary"
              variant="contained"
              onClick = {closeTrainingFilePanel}
            >
              {t("forms.map.cancel")}
            </Button>
            &nbsp;&nbsp;
            <Button
              color="primary"
              variant="contained"
              onClick = {confirmTrainingFilePanel}
            >
              Confirm
            </Button>
      </dialog>

      <dialog  id="help_coordinate_system" style={{border:"3px solid blue", borderRadius:'10px', width:"400px", maxWidth:"400px"}}>
        *{t("forms.acquisition.2.helpCoordinateSystem")}
        <div style={{fontWidth:"9px", color:"#555", paddingTop:"20px"}}>Press [ESC] to close.</div>
      </dialog>

      <dialog  id="help_date_tranning" style={{border:"3px solid blue", borderRadius:'10px', width:"400px", maxWidth:"400px"}}>
        *{t("forms.acquisition.2.dataTrainningDateHint")}
        <div style={{fontWidth:"9px", color:"#555", paddingTop:"20px"}}>Press [ESC] to close.</div>
      </dialog>

      <dialog id="help_tranning_file" style={{border:"3px solid blue", borderRadius:'10px', width:"400px", maxWidth:"400px"}}>
          Input File Format:<br/>
          Format: Comma-Separated Values (.csv)<br/>
          Columns: 3<br/>
          Order: X, Y, Z<br/>
          Decimal: Period (.)<br/>
          <div style={{fontWidth:"9px", color:"#555", paddingTop:"20px"}}>Press [ESC] to close.</div>
      </dialog>

      <HelpButton
        onClickFunction={() => {
          setIsTourOpen(true);
        }}
      />
      
      <div id={"areachooser"}>
        <GoogleMap
          style={{ width: 1000, height: 500 } /* @TODO pass as props */}
          onRegionDrawn={handleDrawing}
        />
      </div>
      <StepperButtons
        navigate={navigate}
        nextDisabled={isBathymetry ? !isDataTranningSelected || !drawn : !drawn}
        onNext={handleChoose}
      >
        <Button
          onClick={handleUndo}
          disabled={drawn === false}
          color="secondary"
          variant="outlined"
        >
          {t("forms.acquisition.2.undo")}
        </Button>
      </StepperButtons>
      {
        // if the user hasn't seen the tour, show it
      }
      {!isBathymetry ? <TourGuider steps={steps} isOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />: ""}
    </Box>
  );
};

export default AOIChooser;
