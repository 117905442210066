import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { first, last } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import TimePeriodSelector from "./TimePeriodSelector";
import StepperButtons from "./StepperButtons";
import CloudSelector from "./CloudSelector";
import ActivityIndicator from "../core/ActivityIndicator.jsx";
import { Actions as Acquisition } from "../../../store/ducks/acquisition";
import { ee } from "../../../services/earth-engine";

import {
  formatDate,
  formatDateDiff,
  datesBetween,
  useLocalStorage,
} from "../../../common/utils";
import { uniteMissionsDates } from "../../../common/algorithms";
import ReactGA from "react-ga";
import TourGuider from "../tour/TourGuider";
import moment from "moment";
import HelpButton from "../core/HelpButton";
import {BathymetryResults} from "../../pages/ProcessingBathymetryPage";
import { WbIncandescentTwoTone } from "@material-ui/icons";
import { get as getSatellite } from "../../../common/satellites";
// useStyles is a hook for styling this component with Material UI's styling solution
const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
  description: {
    margin: theme.spacing(7, 0, 3),
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
}));

// parse ISO date string to timestamp
const toTimestamp = (date) => parseInt(moment(date).format("x"), 10);
// parse timestamps to ISO date string
const toISOString = (timestamp) => moment(timestamp).toISOString();

// This is the page of the third step of the aquisition wizard
// it is supposed to get a start and end date and a max cloud coverage
// and to save it in the storage.
const PeriodChooser = ({ navigate }) => {
  // get available dates from the storage
  const dates = useSelector(
    (state) => state.acquisition.availableDates,
    shallowEqual
  );
  // get available missions from the satellite
  const missions = useSelector(
    (state) => state.acquisition.missions,
    shallowEqual
  );

  const geometry = useSelector(
    (state) => state.acquisition.geometry,
    shallowEqual
  );
  // get the loading state from the redux store
  const working = useSelector((state) => state.common.working);

  const dispatch = useDispatch();
  // get the current language
  const [t] = useTranslation();
  // custom styles
  const classes = useStyles();
  // create a new state for the cloud coverage, starting with 100%
  const [cloudCoverage, setcloudCoverage] = useState(1);
  // create a new state for the time period
  const [period, setPeriod] = useState([]);
  const [isToLoadMedianImage, setIsToLoadMedianImage] = useState(false);
  const [thumbDateImageURL, setThumbDateImageURL] = useState("");
  const [medianThumbURL, setMedianThumbURL] = useState("");



  // create a new state for the start and end date,
  // starting with the first available date and the last available date
  const [start, end] = period;
  const [showIntervalBathymetry, setShowIntervalBathymetry] = useState(false);

  // defines the steps for the tour
  const steps = [
    {
      selector: "#timeselector",
      content: t("tour.acquisition.3.period"),
    },
    {
      selector: "#timeselector",
      content: t("tour.acquisition.3.point"),
    },
    {
      selector: "#cloudselector",
      content: t("tour.acquisition.3.cloud"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showPeriodTour", true);
  let url = window.location.href;
  const isBathymetry = url.indexOf("bathymetry") !== -1;

  useEffect(() => {
    dispatch(Acquisition.loadAvailableImages());
  }, [dispatch]); 

  useEffect(() => {
    console.log("dates - use Effect", dates);
    if (dates ) {
      setPeriod([first(dates).date, last(dates).date]);
    }
  }, [dates]);

  // get all dates between the start and end date with a max cloud coverage
  const selectDates = () => {
    //if(isBathymetry) return [];
    return uniteMissionsDates(missions).filter(
      (entry) => {
       //console.log("image (with res/area)-2",image.getInfo());
       //console.log("Entry",entry);
       //console.log("Comparing cloud coverage:",entry.content, cloudCoverage);
        return entry.content <= cloudCoverage  ; 
      }
    );
  };

  // handle the save of this state and go to the next step
  const handleNext = () => {
    // save the current state in the Google Analytics
    ReactGA.event({
      category: "Acquisition",
      action: "SetcloudCoverage",
      value: cloudCoverage,
    });
    ReactGA.event({
      category: "Acquisition",
      action: "SetPeriod",
      value: start + "-" + end,
    });
    // get the start date
    let begin = new Date(start);
    // !important!
    // set the start date to be one day before
    // this is important to avoid a bug with the acquisition that wasn't getting the images of the day
    // this solution is not perfect but it works and doesn't break the acquisition since the aquisition isn't daily
    begin.setDate(begin.getDate() - 1);
    // transform the start date to ISO string again
    begin = toISOString(toTimestamp(begin));
    // get all the dates in the period
    const dates = selectDates().filter(
      (entry) => entry.date >= begin && entry.date <= end
    );
    // save the dates reversed in the redux storage
    dispatch(Acquisition.setAvailableDates(dates.reverse()));
  };

  // show loading animation when is working
  
  if (working === true || !dates) {
    return <ActivityIndicator textual />;
  }

  const loadMedianImage = function (){
    setIsToLoadMedianImage(true);
  }

  const selectBathymetryDate = function () {
    document.getElementById("bathymetryImageDate").style.backgroundColor = "#CCC";
    document.getElementById("bathymetryImageInterval").style.backgroundColor = "";
    setShowIntervalBathymetry(false);
  }
  
  const selectBathymetryInterval = function (){
    document.getElementById("bathymetryImageDate").style.backgroundColor = "";
    document.getElementById("bathymetryImageInterval").style.backgroundColor = "#CCC"; 
    setShowIntervalBathymetry(true);
  }

  const flatten = selectDates().map((entry) => entry.date);
  const length = datesBetween(flatten, start, end).length;
  let bathymetryImageSelector = (<span></span>);
  let tranningDate = window.sessionStorage.getItem("tranningDate");

  if(isBathymetry){
   let selectedSatelliteIndex = Number.parseInt(window.sessionStorage.getItem( "selectedSatelliteIndex"));
   let satellite = getSatellite(selectedSatelliteIndex);
   console.log("Satellite Dynamic", satellite);  //linha 200, 205
   console.log("missions", satellite.missions);
   let dateFormated = tranningDate.replaceAll("\"","");
   const dateTranningData = dateFormated;
   BathymetryResults.geometry = geometry;
   if(thumbDateImageURL === ""){
      let imExample = ee.ImageCollection(missions[selectedSatelliteIndex].name).filterBounds(geometry).filter(ee.Filter.date(dateFormated, new Date().getFullYear()+"-12-31")).first();
      BathymetryResults.eeImageToProcess = imExample ;
      BathymetryResults.useMedian = false;
      let dt = new Date(imExample.get("system:time_start").getInfo());
      dateFormated = dt.toISOString();
      window.sessionStorage.setItem("selectedImageDateFormated",dateFormated);
      console.log("getImageOnDate (PeriodChooser.jsx)", "dataFormated", dateFormated,"satellite",satellite, "geometry", geometry);
      let imageAndMissionFound = BathymetryResults.getImageOnDate(dateFormated, satellite, geometry);
      console.log("imageAndMissionFound", imageAndMissionFound);
      let im  =  ee.Image(imageAndMissionFound[0]); 
      let ms = imageAndMissionFound[1];
      window.sessionStorage.setItem("selectedMission", JSON.stringify(ms));
      BathymetryResults.baseImageDate  = dateFormated;
      let dtThreshold = new Date(2022, 1, 1);// 01.02.2022
      let min = null;
      let max = null;
      console.log("Name", satellite.name);
      if(satellite.name.toUpperCase().indexOf("LANDSAT") !== -1 ){
        max = 20000;
        min = 7000;
      }else{
        max = 3000;
        min = 0;
        if(dt > dtThreshold){
          max = 4000;
          min = 1000; 
        }
      }
     
      let bandsNames = [ms.bands.red,ms.bands.green,ms.bands.blue];
      console.log("bandsNames", bandsNames,"min",min,"max", max);
      let thumbURL = im.getThumbURL({region: geometry, bands:  bandsNames, dimensions: 256,max:max, min:min, format: 'jpg'});
      console.log("thumbURL", thumbURL);
      BathymetryResults.urlZipDownloadDate = im.getDownloadURL({
        region: geometry,
      });
      console.log("thumbURLzip", BathymetryResults.urlZipDownloadDate);
      setThumbDateImageURL(thumbURL);
   }else{
      if(isToLoadMedianImage && medianThumbURL===""){
        console.log("Period:", start, end); //period image (median)
        BathymetryResults.medianImageParams =[missions[selectedSatelliteIndex].name, geometry, start, end];
        let params = BathymetryResults.medianImageParams;
        let imageMedian = BathymetryResults.getMedianImage(params[0], params[1], params[2], params[3]);
        BathymetryResults.useMedian = true;
        BathymetryResults.eeImageToProcess = imageMedian[1];
        let medianImageURL =  imageMedian[0];
        console.log("thumbURL median", medianImageURL);
        BathymetryResults.urlZipDownloadMedian = imageMedian[1].getDownloadURL({
          region: geometry,
        });
        setMedianThumbURL(medianImageURL);
      }
    }
    bathymetryImageSelector = (<div>
      <Typography variant="subtitle1">{t("forms.acquisition.3.bathymetry.description")}</Typography>
      <div style={{position:'relative', border:"1px dashed #CCC", width:"100%"}}>  
        <div id="bathymetryImageDate" style={{float:'left', padding:'20px', cursor:"pointer"}} onClick={ selectBathymetryDate}>
          <img src={thumbDateImageURL} style={{width:'250px', height:'300px'}} /><br />
          <a href={thumbDateImageURL} download="cassie_image.png" target="_blank">{t("forms.acquisition.3.bathymetry.downloadImage")} (.PNG)</a><br />
          <a href={BathymetryResults.urlZipDownloadDate}  target="_blank">{t("forms.acquisition.3.bathymetry.downloadImage")} (.ZIP)</a>
          <br />
          <Typography variant="subtitle2">{t("forms.acquisition.3.bathymetry.imageDate")}: {dateFormated}</Typography>
          <Typography variant="subtitle2">{t("forms.acquisition.3.bathymetry.dataDate")}: {dateTranningData}</Typography>
        </div>
        <div id="bathymetryImageInterval" style={{float:'left', padding:'20px', cursor:"pointer"}} onClick={ selectBathymetryInterval}>
          {medianThumbURL!==""?
            <div> 
              <img src={medianThumbURL} alt="No period selected" style={{width:'250px', height:'300px'}}  /><br />
              <a href={medianThumbURL} download="cassie_image.png" target="_blank">{t("forms.acquisition.3.bathymetry.downloadImage")} (.PNG)</a><br />
              <a href={BathymetryResults.urlZipDownloadMedian} target="_blank">{t("forms.acquisition.3.bathymetry.downloadImage")} (.ZIP)</a>
            </div>
            :
            <div><Typography variant="subtitle2"> {t("forms.acquisition.3.bathymetry.intervalSelection")} </Typography></div>}
          <Typography variant="subtitle2">{t("forms.acquisition.3.bathymetry.medianImagem")} </Typography>
          <Typography variant="subtitle2">{t("forms.acquisition.3.bathymetry.intervalDefinition")} </Typography>
        </div>
      </div>   
    </div>);
  }
 
  return (
    <Box>
      <HelpButton 
        onClickFunction={() => {
          setIsTourOpen(true);
        }}
      />
      
      <Box
        className={classes.wrapper}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >

        <Box  className={classes.wrapper}>
          {bathymetryImageSelector}
        </Box>

        { !isBathymetry || showIntervalBathymetry ?  
     
          <TimePeriodSelector
            dates={flatten}
            start={start}
            end={end}
            onChange={(start, end) => setPeriod([start, end])}
          />: null }

        { isBathymetry && showIntervalBathymetry ?
            <Box>
              <br /> <br />
              <Button
              color="primary"
              variant="contained"
              onClick = {loadMedianImage}
            >
              Generate median image
            </Button>
          </Box>
        : null}
   
        { !isBathymetry || showIntervalBathymetry ? 
        <Box className={classes.description}>
          <Typography variant="subtitle1" align="center">
            {t("forms.acquisition.3.period")}: {formatDate(start)}{" "}
            {t("forms.acquisition.3.to")} {formatDate(end)}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {formatDateDiff(start, end)}, {length}{" "}
            {t(
              `forms.acquisition.3.imageQuantity.${
                length === 1 ? "singular" : "plural"
              }`
            )}
          </Typography>
        </Box>
        : null }

        { !isBathymetry ? 
        <CloudSelector
          level={cloudCoverage}
          onChange={(cloudCoverage) => setcloudCoverage(cloudCoverage)}
        />
        : null }
        <StepperButtons navigate={navigate} onNext={handleNext} />
        {!isBathymetry ? <TourGuider steps={steps} isOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />: ""}
      </Box>
    </Box>
  );
};

export default PeriodChooser;
