import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { Actions as AuthActions } from "../../../store/ducks/auth";
import Dygraph from 'dygraphs';
import {
  Container,
  Avatar,
  Snackbar,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  Link,
  Badge,
  Card,
  CardContent,
  CardActions,
  Slider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import googleLogo from "../../../resources/googleLogo.svg";
import geeLogo from "../../../resources/gee_icon.png";
import googleLogoDisabled from "../../../resources/googleLogoDisabled.svg";
import Slide from "@material-ui/core/Slide";
import HomePageLayout from "../../components/homepage/HomePageLayout";
import { useLocalStorage } from "../../../common/utils";
import {  Bar, Chart } from "react-chartjs-2";
// useStyles is a hook for Material-UI's styling.
const useStyles = makeStyles((theme) => ({
  backdrop: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    zIndex: "-1",
    top: 0,
    left: 0,
    backgroundColor: theme.palette.primary.light,
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    background: "url(/bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    minHeight: "75vh",
  },
  bay: {
    padding: "60px 0px",
    background: "url(/bay.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  risc: {
    padding: "60px 0px",
    background: "url(/port.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  headingContainer: {
    paddingBottom: "10vh",
    paddingTop: "15vh",
  },
  content: {
    padding: "60px 0px",
  },
  fullW: {
    with: "100%",
  },
  flex: {
    display: "flex",
  },
  logo: {
    fontSize: 120,
    color: "white",
    margin: "0px 0px 20px 0px",
  },
  title: {
    fontWeight: "600",
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    fontSize: "1.5rem",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    backgroundColor: theme.palette.primary[200],
    padding: "40px 48px",
  },
  i18nSwitchers: {
    textAlign: "right",
    backgroundColor: "white",
  },
  i18nSwitch: {
    padding: "5px 10px",
    margin: "0 10px",
  },
  flag: {
    marginRight: "5px",
    height: "25px",
    width: "25px",
  },
  description: {
    margin: "30px 0px 40px 0px",
    color: "rgba(0, 0, 0, 0.85)",
  },
  google: {
    margin: "-4px 20px -4px -15px",
  },
  intro_gif: {
    maxWidth: "100%",
  },
  spaced_text: {
    marginBottom: theme.spacing(4),
  },
  bay_text: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5),
    borderRadius: theme.spacing(1),
  },
  spaced_btn: {
    marginRight: "16px",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    border: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  sponsor_img: {
    height: theme.spacing(6),
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  sponsor_img_sqr: {
    height: theme.spacing(13),
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  sponsor_img_partner: {
    height: theme.spacing(10),
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  avatar_link: {
    border: "none",
  },
  snack: {
    maxWidth: "75%",
  },
  cpnq_logo: {
    maxWidth: "150px",
  },
}));

// Create a custom Typography component.
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

// Create a custom Avatar component.
const SmallAvatar = withStyles((theme) => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: ".75rem",
  },
}))(Avatar);

// Create a transition component.
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

// main function
function HomePage() {
  // check if the authentication is busy
  const busy = useSelector((state) => state.auth.authenticating);

  const dispatch = useDispatch();
  // use i18n
  const [t] = useTranslation();
  // use custom styles
  const classes = useStyles();

  // create an array for the different groups
  const groupsData = {
    loc: {
      name: "LOC UFSC",
      img: "loc.png",
      link: "https://loc.ufsc.br/",
    },
    lia: {
      name: "LIA Univali",
      img: "lia.png",
      link: "http://ialab.com.br/",
    },
    atlantic: {
      name: "CoLAB + Atlantic",
      img: "atlantic.png",
      link: "https://colabatlantic.com/",
    },
    univali: {
      name: "UNIVALI",
      img: "univali.png",
      link: "https://univali.br",
    },
    waikato: {
      name: "WAIKATO",
      img: "waikato_university.jpg",
      link: "https://www.waikato.ac.nz/",
    }
  };

  // create an array for the different authors
  const authorsData = {
    anita:{
      name: "Anita M. R. Fernandes",
      img: "anita.jpg",
      link: "",
      group: groupsData.univali,
      role: t("home.members.roles.desenvolvimento"),
    },
    daniel: {
      name: "Daniel Pais",
      img: "daniel_paes.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.bolsista"),
    },
    bugui: {
      name: "Carlos H. Bughi ",
      img: "bughi.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.desenvolvimento"),
    },
    guilherme: {
      name: "Guilherme V. da Silva",
      img: "guilherme.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.validacao"),
    },
    anderson: {
      name: " Anderson B. da Silva ",
      img: "anderson.png",
      link: "",
      group: "",
      role: t("home.members.roles.validacao"),
    },
    joao: {
      name: "João Luiz B. de Carvalho ",
      img: "joao.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.validacao"),
    },
    leonardo: {
      name: "Leonardo G. de Lima",
      img: "leonardo.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.validacao"),
    },
    larissa: {
      name: "Larissa R. S. Sousa",
      img: "larissa.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.bolsista"),
    },
    luiza: {
      name: "Luíza Fiorini",
      img: "luiza.png",
      link: "",
      group: "",
      role: t("home.members.roles.bolsista"),
    },
    mario: {
      name: "Mario Luiz Mascagni",
      img: "mario.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.bolsista"),
    },
    ramices: {
      name: "Ramicés S. Silva",
      img: "ramices.jpg",
      link: "",
      group: "",
      role: t("home.members.roles.bolsista"),
    },

    denis: {
      name: "Dennis Kerr Coelho",
      img: "denis.png",
      link: "https://www.linkedin.com/in/dennis-kerr-coelho-902a184/",
      group: "",
      role: t("home.members.roles.desenvolvimento"),
    },

    andrigo: {
      name: "Andrigo Borba dos Santos",
      img: "andrigo.png",
      link: "http://lattes.cnpq.br/7210627337069209",
      group: "",
      role: t("home.members.roles.bolsista"),
    },

    cicero: {
      name: "Cícero Vicente Ferreira Junior",
      img: "cicero.png",
      link: "https://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4406638H1",
      group: "",
      role: t("home.members.roles.bolsista"),
    },

    henrique: {
      name: "Henrique Faria Cordeiro",
      img: "henrique.png",
      link: "https://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K8071490T9",
      group: "",
      role: t("home.members.roles.bolsista"),
    },
     
    klein: {
      name: "Antonio H.F. Klein",
      img: "klein.jpg",
      group: groupsData.loc,
      role: t("home.members.roles.coord_geral"),
      link: "http://lattes.cnpq.br/2354029280846247",
    },
    rudimar: {
      name: "Rudimar L.S. Dazzi",
      img: "rudimar.jpg",
      group: groupsData.lia,
      mail: "rudimar@univali.br",
      role: t("home.members.roles.coord"),
      link: "http://lattes.cnpq.br/9340343036686762",
    },
    lyra: {
      name: "Rodrigo Lyra",
      img: "rodrigo.jpg",
      group: "groupsData.lia",
      mail: "rlyra@univali.br",
      role: t("home.members.roles.desenvolvimento"),
      link: "http://lattes.cnpq.br/0117343254850007",
    },

    gabriel: {
      name: "Gabriel Libório",
      img: "Gabriel.jpeg",
      group: "",
      mail: "",
      role: t("home.members.roles.desenvolvimento"),
      link: "",
    },
    sofia: {
      name: "Sofia Aguiar",
      img: "sofia.jpg",
      group: groupsData.atlantic,
      role: t("home.members.roles.researcher"),
      link: "https://www.behance.net/sofiaaguia8215",
    },
    pedro: {
      name: "Luis Pedro Almeida",
      img: "pedro.png",
      group: groupsData.atlantic,
      role: t("home.members.roles.desenvolvimento"),
      link: "http://lattes.cnpq.br/4892957787322492",
    },
    unas: {
      name: "Pedro Unas",
      img: "unas.jpg",
      group: groupsData.atlantic,
      role: t("home.members.roles.researcher"),
      link: "https://github.com/pedrounas",
    },
    israel: {
      name: "Israel Efraim de Oliveira",
      img: "israel.png",
      group: groupsData.lia,
      mail: "israel.oliveira@edu.univali.br",
      role: t("home.members.roles.atp"),
      link: "https://github.com/IsraelEfraim",
    },
    alisson: {
      name: "Alisson Steffens",
      img: "alisson.jpeg",
      group: groupsData.lia,
      mail: "ali.steffens@gmail.com",
      role: t("home.members.roles.capes"),
      link: "https://alissonsteffens.com",
    },
    rafaelqg: {
      name: "Rafael Q. Gonçalves",
      img: "rafael_goncalves_profile.png",
      group: groupsData.univali,
      mail: "rafaelqg@univali.br",
      role: t("home.members.roles.desenvolvimento"),
      link: " http://lattes.cnpq.br/2128958035598438",
    },
    laispool: {
      name: "Lais Pool",
      img: "Lais_0948.jpg",
      group: groupsData.loc,
      mail: "lais.pool@gmail.com",
      role: t("home.members.roles.bolsista"),
      link: "https://github.com/laispool",
    },
    vinicius: {
      name: "Vinícius G. Martins",
      img: "vinicius.png",
      group: groupsData.lia,
      role: t("home.members.roles.fumdes"),
      link: "https://github.com/vinigmartins",
    },
    fernando: {
      name: "Fernando Concatto",
      img: "fernando.jfif",
      group: groupsData.lia,
      role: t("home.members.roles.fumdes"),
      link: "https://github.com/concatto",
    },
    wagner: {
      name: "Wagner Costa",
      img: "wagner_costa.jpg",
      group: groupsData.waikato,
      role: t("home.members.roles.desenvolvimento"),
      link: "",
    },
  };

  // create an array with active members
  const authors = [
    authorsData.anita,
    authorsData.klein,
    authorsData.bugui,
    authorsData.daniel,
    authorsData.guilherme,
    
    authorsData.pedro,
    authorsData.rafaelqg,
    authorsData.denis,
    //authorsData.lyra,
    authorsData.gabriel,
    authorsData.wagner,
    //authorsData.sofia,
    authorsData.anderson,
    authorsData.joao,
    authorsData.leonardo,

    authorsData.laispool,
    authorsData.larissa,
    authorsData.luiza,
    authorsData.mario,
    authorsData.ramices,
    authorsData.cicero,
    authorsData.andrigo,
    authorsData.henrique,
  ];

  // create an array with published papers
  const papers = [
    {
      kind: t("home.papers.paper_text"),
      title:
        "Coastal Analyst System from Space Imagery Engine (CASSIE): Shoreline management module",
      event: "Environmental Modelling & Software 2021",
      authors: [
        authorsData.pedro,
        authorsData.israel,
        authorsData.lyra,
        authorsData.rudimar,
        authorsData.vinicius,
        authorsData.klein,

      ],
      link: "https://www.sciencedirect.com/science/article/abs/pii/S1364815221000761",
    },
    {
      kind: t("home.papers.resumo_text"),
      title:
        "e-C.A.S.S.I.E.: Uma Ferramenta para Análise e Mapeamento de Regiões Costeiras Utilizando a Plataforma Google Earth Engine",
      event: "II SBPA & XI ENCOGERCO 2018",
      authors: [
        authorsData.fernando,
        authorsData.pedro,
        authorsData.lyra,
        authorsData.rudimar,
        authorsData.klein,
      ],
      link: "http://www.praiaegestao.com.br/theme/images/ANAISSBPAEENCOGERCO_2018.pdf#page=252",
    },
    {
      kind: t("home.papers.resumo_text"),
      title:
        "Desenvolvimento de uma Ferramenta Integrada ao Google Earth Engine para a Análise de Ambientes Costeiros",
      event: "Computer on the Beach 2018",
      authors: [
        authorsData.fernando,
        authorsData.pedro,
        authorsData.lyra,
        authorsData.rudimar,
        authorsData.klein,
      ],
      link: "https://siaiap32.univali.br/seer/index.php/acotb/article/view/12871",
    },
    {
      kind: t("home.papers.curso_text"),
      title:
        "Determinação e análise da evolução da linha de costa com ferramenta do Google Earth Engine (e-CASSIE)",
      event: "LAPECO 2019",
      authors: [authorsData.pedro, authorsData.lyra, authorsData.israel],
      link: "http://lapeco2019.blogspot.com/p/blog-page_30.html",
    },
  ];

  // dispatch Authentication action with useEffect
  useEffect(() => {
    dispatch(AuthActions.loadClientAuth());
  }, []);

  // create devAdvOpen state
  const [devAdvOpen, setDevAdvOpen] = React.useState(true);

  // create cookiesAdvOpen state
  const [cookiesAdvOpen, setCookiesAdvOpen] = useLocalStorage(
    "showNewCookiesADV",
    true
  );

  // function to handle devAdvClose event (from devAdvOpen) deppendin on the reason
  const handleDevAdvClose = (reason) => {
    // if the reason isn't "clickaway" we close the devAdv
    if (reason !== "clickaway") {
      setDevAdvOpen(false);
    }
  };

  // function to handle cookiesAccept event (from cookiesAdvOpen) deppendin on the reason
  const handleCookiesAccept = (reason) => {
    // if the reason isn't "clickaway" we close the cookiesAdv and post the cookiesAccept event to the API
    if (reason !== "clickaway") {
      setCookiesAdvOpen(false);
     // postAcceptingToAPI();
    }
  };

  // function that send a POST request to the API to accept cookies
  const postAcceptingToAPI = () => {
    fetch("https://cassie-api.vercel.app/api/cookies", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        read: true,
      }),
    })
      .then((response) => {
        // if we get a bad response we show the error
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
      })
      .catch((error) => console.error("Error: ", error));
  };

/*

//start: import crosshair plugin
Dygraph.Plugins.Crosshair = (function() {
  "use strict";
  var crosshair = function(opt_options) {
    this.canvas_ = document.createElement("canvas");
    opt_options = opt_options || {};
    this.direction_ = opt_options.direction || null;
  };

  crosshair.prototype.toString = function() {
    return "Crosshair Plugin";
  };
  crosshair.prototype.activate = function(g) {
    g.graphDiv.appendChild(this.canvas_);

    return {
      select: this.select,
      deselect: this.deselect
    };
  };

  crosshair.prototype.select = function(e) {
    if (this.direction_ === null) {
      return;
    }

    var width = e.dygraph.width_;
    var height = e.dygraph.height_;
    this.canvas_.width = width;
    this.canvas_.height = height;
    this.canvas_.style.width = width + "px";    // for IE
    this.canvas_.style.height = height + "px";  // for IE

    var ctx = this.canvas_.getContext("2d");
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = "rgba(0, 0, 0,0.3)";
    ctx.beginPath();

    var canvasx = Math.floor(e.dygraph.selPoints_[0].canvasx) + 0.5; // crisper rendering

    if (this.direction_ === "vertical" || this.direction_ === "both") {
      ctx.moveTo(canvasx, 0);
      ctx.lineTo(canvasx, height);
    }

    if (this.direction_ === "horizontal" || this.direction_ === "both") {
      for (var i = 0; i < e.dygraph.selPoints_.length; i++) {
        var canvasy = Math.floor(e.dygraph.selPoints_[i].canvasy) + 0.5; // crisper rendering
        ctx.moveTo(0, canvasy);
        ctx.lineTo(width, canvasy);
      }
    }

    ctx.stroke();
    ctx.closePath();
  };

  crosshair.prototype.deselect = function(e) {
    var ctx = this.canvas_.getContext("2d");
    ctx.clearRect(0, 0, this.canvas_.width, this.canvas_.height);
  };

  crosshair.prototype.destroy = function() {
    this.canvas_ = null;
  };

  return crosshair;
})();

//end: import crosshair plugin

  
//start: histogram graph parameters

const labels = [0.75, 1.25, 1.75, 2.25];
const minRange = labels[0];
const maxRange = labels[labels.length-1];


const [rangeValue, setRangeValue]  = useState(minRange);
const handleChange = function(event) {
  this.setState({rangeValue: event.target.value});
};

const data = [1, 2, 3, 4];
var dataBar = [
  [1, 5],
  [2, 10],
  [3, 15],
  [4, 7],
  [5, 3]
];

      // Custom bar plotter function
      function barPlotter(e) {
        var ctx = e.drawingContext;
        var points = e.points;
        var y_bottom = e.dygraph.toDomYCoord(0);

        ctx.fillStyle = e.color;

        // Set the width of the bars
        var barWidth = 0.5 * (points[1].canvasx - points[0].canvasx);

        for (var i = 0; i < points.length; i++) {
            var p = points[i];
            var center_x = p.canvasx;

            ctx.fillRect(center_x - barWidth / 2, p.canvasy, barWidth, y_bottom - p.canvasy);
            ctx.strokeRect(center_x - barWidth / 2, p.canvasy, barWidth, y_bottom - p.canvasy);
        }
    }

setTimeout( ()=>{
  console.log("Plotting chart Dygraph");


const g = new Dygraph(document.getElementById('graph'),
  dataBar,
            {
                labels: ["Bin", "Frequency"],
                plotter: barPlotter,
                
                plugins: [
                  new Dygraph.Plugins.Crosshair({
                    direction: "vertical"
                  }) 
                ],

                pointClickCallback: function(e, pt) {
                  console.log("Dygraph: clicked point", pt);
              },
            color: "orange",    //Accepts CSS colour values.
            //There are other properties you can set too:
            strokeWidth: 1,
            drawPoints: true,
            pointSize: 15,
            highlightCircleSize: 3
                  
            }, {
    fillGraph: false
  });
}, 4000);



const optionsBarChart = {
  pan: {
    enabled: true,
    mode: "xy"
  },
  zoom: {
    enabled: true,
    drag: false,
    mode: "xy"
  },
  scales: {
    x: {
      type: "linear",
      offset: false,
      gridLines: {
        offsetGridLines: false
      },
      title: {
        display: true,
        text: "Arrivals per minute"
      }
    }
  },
  onClick: function(evt, element) {
    console.log("Chart clicked", element);
    if(element.length > 0){
      var index = element[0]._index;
      console.log("Point clicked:", labels[index], data[index]);
      document.getElementById("threshold").value = labels[index];
    }
  },

};

const plugins = [
  {
    afterDraw: (chart) => {
      console.log("After draw...",chart);//to-do evaluate values
      // eslint-disable-next-line no-underscore-dangle
    //  if (chart.tooltip._active && chart.tooltip._active.length) {
        // find coordinates of tooltip
        //const activePoint = chart.tooltip._active[0];
        const { ctx } = chart;
        //const { x } = activePoint.element;
        
        const width = chart.width;
        const barWidth = width/chart.boxes.length;
 
        const  x  = width;

        const topY = chart.scales["x-axis-0"].top;
        const bottomY = 0; //chart.scales["y-axis-0"].bottom; 
        console.log("TopY", topY, "bottomY", bottomY); 
        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x,  topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#F00';
        ctx.stroke(); 
        //ctx.restore();
        console.log("End draw...",ctx);
    },
  },
];

let dataBarChart = {
  labels: labels,
  datasets: [
    {
      label: 'Dataset 1',
      borderColor: "blac",
      lineTension: 0,
      fill: false,
      backgroundColor: "#BB",
      borderJoinStyle: "round",
      data: data, 
      borderWidth: 0.2,
      barPercentage: 1,
      categoryPercentage: 1,
      hoverBackgroundColor: "darkgray",
      barThickness: "flex"
    },
  ]
};
//end: histogram graph parameters

//html code for bar charts
 <div>
             
    <Bar  options={optionsBarChart} data={dataBarChart} plugins={plugins}  />
    <br />
    <input 
      style={{width:"100%"}} 
      type="range" id="threshold_chart_selection" 
      min={minRange} 
      max={maxRange}
      value={rangeValue} 
      onChange={handleChange}
      />

      <Slider
              key={1}
              value={rangeValue}
              step={0.05}
              min={minRange}
              max={maxRange}
              onChange={(_, value) => {
                setRangeValue(value);
              }}
            />
  </div>


<div id="graph"></div>
            



*/
  return (
    <HomePageLayout>
      <Box className={classes.heading}>
        <Container
          className={classes.headingContainer}
          maxWidth="md"
          display="flex"
          flexdirection="column"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <WhiteTextTypography className={classes.title} variant="h2">
                {t("self.fullTitle")}
              </WhiteTextTypography>
            </Grid>

            <Grid item xs={12} md={8}>
              <WhiteTextTypography className={classes.subtitle} variant="body1">
                {t("self.shortDesc")}
              </WhiteTextTypography>
            </Grid>

            <Grid item xs={12}>
              <Button
                  variant="contained"
                  className={classes.spaced_btn}
                  style = {{marginTop:"10px"}}
                  color="primary"
                  size="large"
                  href="https://earthengine.google.com/signup/"
                  m="10"
                >
                  <Avatar
                    className={classes.google}
                    variant="square"
                    alt="Google Logo"
                    // we use the Google logo as the avatar
                    // the avatar is disabled when the Auth process is in progress
                    src={geeLogo}
                  />
                  {t("home.instructions.btnEngineSingUp")}
              </Button>

              <Button
                disabled={busy}
                variant="contained"
                style = {{marginTop:"10px"}}
                size="large"
                color="primary"
                className={classes.spaced_btn}
                // start Authentication proccess when the button is clicked
                onClick={() =>
                  dispatch(
                    AuthActions.begin(() => dispatch(push("/main/selection")))
                  )
                }
              >
                <Avatar
                  className={classes.google}
                  variant="square"
                  alt="Google Logo"
                  // we use the Google logo as the avatar
                  // the avatar is disabled when the Auth process is in progress
                  src={busy ? googleLogoDisabled : googleLogo}
                />
                {t("auth.signin")}
              </Button>

             

            </Grid>
          </Grid>
        </Container>
      </Box>

      

      <Box className={classes.content} bgcolor="white">
        <Container maxWidth="md" display="flex" flexdirection="column">
          <Typography className={classes.title} variant="h3" align="center">
            {t("home.instructions.title")}
          </Typography>
          <Grid container p={5} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.spaced_text} align="center">
                {t("home.instructions.text")}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="outlined"
              className={classes.spaced_btn}
              color="primary"
              href="https://earthengine.google.com/signup/"
              m="10"
            >
              {t("home.instructions.btnEngineSingUp")}
            </Button>
            <Button
              variant="outlined"
              className={classes.spaced_btn}
              color="secondary"
              href={t("home.instructions.linkManual")}
            >
              {t("home.instructions.btnManual")}
            </Button>
            <Button
              variant="outlined"
              className={classes.spaced_btn}
              color="default"
              href={t("home.instructions.linkVideo")}
            >
              {t("home.instructions.btnVideo")}
            </Button>
          </Box>
        </Container>
      </Box>

      <Box className={classes.content}>
        <Container maxWidth="md" display="flex" flexdirection="column">
          <Typography className={classes.title} variant="h3" align="center">
            {t("home.about.title")}
          </Typography>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="/logo.svg"
                alt={t("home.about.title")}
                className={classes.intro_gif}
                style ={{width:"70%"}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">{t("self.abstract")}</Typography>
              <Button
                  variant="contained"
                  className={classes.spaced_btn}
                  style = {{marginTop:"10px"}}
                  color="primary"
                  size="large"
                  href="https://cassiecore.paginas.ufsc.br/"
                  target="_blank"
                  m="10"
                >
                  {t("home.warning.link")}
            </Button>
           
            </Grid>
          </Grid>
        </Container>
      </Box>

      
      <Box className={classes.content} bgcolor="white">
        <Container maxWidth="md" display="flex" flexdirection="column">
          <Typography className={classes.title} variant="h3" align="center">
            {t("home.members.title")}
          </Typography>
          <Grid container spacing={3}>
            {authors.map((pesq) => (
              <Grid key={pesq.name} item xs={12} md={4} align="center">
                <Link href={pesq.link} target="_blank" rel="noopener">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Box
                        // href={pesq.group.link}
                        className={classes.avatar_link}
                        target="_blank"
                        rel="noopener"
                      >
                        
                        <Tooltip style={{display:"none"}} title="-">
                          <SmallAvatar
                            alt={pesq.group.name}
                            src={"/grupos/" + pesq.group.img}
                          />
                        </Tooltip>
                        
                      </Box>
                    }
                  >
                    <Avatar
                      alt={pesq.name}
                      src={"/equipe/" + pesq.img}
                      className={classes.large}
                    />
                  </Badge>
                </Link>

                <Typography variant="h5">{pesq.name}</Typography>
                <Typography variant="body1" >{pesq.role}</Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={classes.content}>
        <Container maxWidth="md" display="flex" flexdirection="column">
          <Grid container spacing={9} justifyContent="center">
            <Grid item xs={12} md={6} align="center">
              <Typography className={classes.title} variant="h4">
                {t("home.sponsor.realiza")}
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={4} align="center">
                    <Link href="https://loc.ufsc.br/" target="_blank" rel="noopener">
                    <img
                      src={"/grupos/loc.png"}
                      alt="LOC"
                      className={classes.sponsor_img}
                      style ={{ height: "120px", marginTop:"-35px"}}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={4} align="center">
                  <Link href="http://ialab.com.br/" target="_blank" rel="noopener">
                    <img
                      src={"/grupos/lia_sm.png"}
                      alt="LIA"
                      className={classes.sponsor_img}
                    />
                  </Link>
                </Grid>


                <Grid item xs={12} md={4} align="center">
                  <Link href="https://appix.com.br/" target="_blank" rel="noopener">
                    <img
                      src={"/grupos/appix_logo.png"}
                      alt="LIA"
                      className={classes.sponsor_img}
                    />
                  </Link>
                </Grid>
                

              </Grid>
              
              
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Typography className={classes.title} variant="h4">
                {t("home.sponsor.apoio")}
              </Typography>

              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} align="center">
                  <Link href="http://cnpq.br/" target="_blank" rel="noopener">
                    <img
                      src={"/grupos/cnpq.png"}
                      alt="CNPQ"
                      className={classes.sponsor_img}
                    />
                  </Link>
                  <Typography className={classes.spaced_text} variant="body1">
                    {t("home.sponsor.apoio_desc")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} align="center">
                  <img
                    src={"/grupos/boticario.png"}
                    alt="Boticario"
                    className={classes.sponsor_img}
                    style={{width:"200px", height:"120px"}}
                  />
                </Grid>
              </Grid>             
            </Grid> 
            
            <Grid style={{display:"none"}} item xs={12} align="center">
              <Typography className={classes.title} variant="h4">
                {t("home.sponsor.inst")}
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6} align="center">
                  <Link
                    href="http://redeclima.ccst.inpe.br/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={"/grupos/rede.png"}
                      alt="Rede Clima"
                      className={classes.sponsor_img_sqr}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} md={6} align="center">
                  <Link
                    href="https://www.icmbio.gov.br/cepsul/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={"/grupos/cepsul.png"}
                      alt="CEPSUL"
                      className={classes.sponsor_img_sqr}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} align="center">
              <Typography className={classes.title} variant="h4">
                {t("home.sponsor.partners")}
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={3} align="center">
                  <Link
                    href="https://www.univali.br/Paginas/default.aspx"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={"/grupos/univali.png"}
                      alt="Univali"
                      className={classes.sponsor_img_partner}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} md={3} align="center">
                  <Link href="https://ufsc.br/" target="_blank" rel="noopener">
                    <img
                      src={"/grupos/ufsc.png"}
                      alt="UFSC"
                      className={classes.sponsor_img_partner}
                    />
                  </Link>
                </Grid>
            
                <Grid item xs={12} md={3} align="center">
                  <Link
                    href="https://colabatlantic.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={"/grupos/atlantic.png"}
                      alt="CoLAB + Atlantic"
                      className={classes.sponsor_img_partner}
                    />
                  </Link>

               


                </Grid>
                <Grid item xs={12} md={3} align="center">
                    <img
                      src={"/grupos/GRIF.png"}
                      alt="GRIFFTH"
                      className={classes.sponsor_img_partner}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} align="center">
                    <img
                      src={"/grupos/UFMA.png"}
                      alt="UFMA"
                      className={classes.sponsor_img_partner}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={3} align="center">
                    <img
                      src={"/grupos/IMA.png"}
                      alt="IMA"
                      className={classes.sponsor_img_partner}
                    />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
      
        </Container>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className={classes.snack}
        open={devAdvOpen}
        autoHideDuration={3000}
        style={{display:"none"}}
        onClose={handleDevAdvClose}
        TransitionComponent={TransitionLeft}
      >
        <Alert
          elevation={5}
          variant="filled"
          onClose={handleDevAdvClose}
          severity="warning"
        >
          {t("home.warning.text")}{" "}
          <Link color="textPrimary" href="/problems">
            {t("home.warning.link")}
          </Link>
        </Alert>
   </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.snack}
        open={cookiesAdvOpen}
        onClose={handleCookiesAccept}
        message={t("home.cookies.text")}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={handleCookiesAccept}
            >
              {t("home.cookies.link")}
            </Button>
          </React.Fragment>
        }
      ></Snackbar>
    </HomePageLayout>
  );
}

export default HomePage;
